<template>
     <div class = "flex flex-col items-center text-[18px]">
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-blue-300 rounded-sm border-2 ml-[3px] mr-[3px] border-blue-500"><span>Редактор</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Субъекты<br>и люди</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Видео</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Первая<br>полоса</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Контроль<br>новостей</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Списки</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Администри-<br>рование</span></a>
        <a href="" class = "flex text-center w-[100%] h-[11%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span>Настройки</span></a>
        <a href="" class = "flex text-center w-[100%] h-[12%] items-center justify-center bg-slate-200 rounded-sm border-2 ml-[3px] mr-[3px] border-neutral-600"><span></span></a>
      </div>
</template>